import { classNames, Theme } from '../styles/style';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useDisconnect } from 'wagmi';
import Avatar from '../shared/Avatar';
import Logo from '../icons/logo';

const links = [
  {
    title: 'Live Demo',
    href: '/#demo',
    onClick: (e) => {
      e.preventDefault();
      document.getElementById('demo').scrollIntoView({ behavior: 'smooth' });
    },
  },
  {
    title: 'Use Cases',
    href: '/#use-cases',
    target: 'use-cases',
    onClick: (e) => {
      e.preventDefault();
      document.getElementById('use-cases').scrollIntoView({ behavior: 'smooth' });
    },
  },
  {
    title: 'Features',
    href: '/#features',
    onClick: (e) => {
      e.preventDefault();
      document.getElementById('features').scrollIntoView({ behavior: 'smooth' });
    },
  },
  {
    title: 'FAQ',
    href: '/#faq',

    onClick: (e) => {
      e.preventDefault();
      document.getElementById('faq').scrollIntoView({ behavior: 'smooth' });
    },
  },
  {
    title: 'Docs',
    href: 'https://dev.moonpay.com',
    onClick: null,
  },
  {
    title: 'Blog',
    href: 'https://blog.ethpass.xyz',
    onClick: null,
  },
];
export default function NavHeader({ renderLogin = true }) {
  const { disconnect } = useDisconnect();
  const { data: session } = useSession();
  const router = useRouter();
  const { asPath } = router;
  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <a className="flex-shrink-0 flex items-center" href="/">
                  <Logo height={25} theme={Theme.LIGHT} />
                </a>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  {session?.user && (
                    <a
                      key="dashboard"
                      href="/dashboard"
                      className="border-transparent text-gray-500 hover:border-indigo-500 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                      Dashboard
                    </a>
                  )}
                  {links.map((link, i) => (
                    <a
                      key={`${link}_${i}`}
                      href={link.href}
                      onClick={asPath === '/' ? link.onClick : null}
                      className="border-transparent text-gray-500 hover:border-indigo-500 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                      {link.title}
                    </a>
                  ))}
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                {renderLogin ? (
                  <div className="hidden sm:ml-6 lg:block">
                    <div className="flex items-center">
                      <Menu as="div" className="ml-3 relative z-50">
                        <div>
                          <Menu.Button className="flex items-center text-sm  border-gray-500">
                            <Avatar user={session?.user} />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95">
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  onClick={(e) => {
                                    e.preventDefault();
                                    router.push('/dashboard');
                                  }}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                  )}>
                                  Dashboard
                                </a>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                ) : null}
                {/* Profile dropdown */}
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              {session?.user && (
                <Disclosure.Button
                  key="dashboard"
                  as="a"
                  href="/dashboard"
                  className="border-transparent text-gray-500 hover:bg-indigo-50 hover:border-indigo-500 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium">
                  Dashboard
                </Disclosure.Button>
              )}
              {links.map((link, i) => (
                <Disclosure.Button
                  key={`${link}_${i}`}
                  as="a"
                  href={link.href}
                  onClick={asPath === '/' ? link.onClick : null}
                  className="border-transparent text-gray-500 hover:bg-indigo-50 hover:border-indigo-500 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium">
                  {link.title}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
